import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.sass']
})

export class NotFoundComponent implements OnInit {
  swiftRawCode = 'import SwiftUI\n\nstruct PageNotFound : View {\n' +
    '    var body: some View {\n' +
    '        VStack {\n' +
    '            Image("page-not-found")\n' +
    '            Text("Page was not found")\n' +
    '                .font(.largeTitle)\n' +
    '            Text("This webpage does not exist.")\n' +
    '                .font(.footnote)\n' +
    '            NavigationLink(destination: Home()) {\n' +
    '                Text("Go back home")\n' +
    '            }\n' +
    '        }\n' +
    '    }\n' +
    '}';
  swiftCode = this.swiftRawCode
    .replace(/ /g, "&nbsp;")
    .replace(/\n/g, "<br />")
    .applyTint(/(".*?")/g, "codeString")
    .applyTint(/(SwiftUI|PageNotFound)/g, "codeFramework")
    .applyTint(/(struct|var)/g, "codeKeywords")
    .applyTint(/(Text|Image|NavigationLink|Home|destination)/g, "codeView")
    .applyTint(/(\.largeTitle|\.footnote)/g, "codeProperty")
    .applyTint(/(import)/g, "codeImport");

  constructor() { }

  ngOnInit() {
  }

}

declare global {
  interface String {
    applyTint(searchString: RegExp, colorClass: string): string;
  }
}

String.prototype.applyTint = function (searchString: RegExp, colorClass: string): string {
  return this.replace(searchString, `<span class="${colorClass}">$1</span>`);
}