import { Component, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { WindowRefService } from '../window.service';
import axios from 'axios';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.sass'],
  providers: [WindowRefService],
})
export class AboutMeComponent implements AfterViewInit {

  readonly job = {
    "currentJob": {
        "long": "Mobile Application Developer",
        "short": "Mobile Application Developer for iOS and Android"
    },
    "currentEmployer": "CLAAS",
};
  readonly age = moment().diff('1998-05-08', 'years');

  readonly urlBase = 'https://yanniks.de';
  windowService: WindowRefService;
  darkmode = window.matchMedia('(prefers-color-scheme: dark)');
  // darkmode = {addListener: (e: any) => undefined, matches: true};
  map: any;

  constructor(windowService: WindowRefService) {
    this.windowService = windowService;
  }

  recenterMap() {
    const mapkit = (this.windowService.nativeWindow as any).mapkit;
    setTimeout(() => {
      this.map.center = new mapkit.Coordinate(50.82588, 9.976677);
    }, 100);
  }

  async addMapItems() {
    const mapkit = (this.windowService.nativeWindow as any).mapkit;

    const res = await axios.get(`${this.urlBase}/flightradar`);
    const flights = res.data;

    const flightCoords = flights.map((flight) => {
      return [new mapkit.Coordinate(flight.from.latitude, flight.from.longitude),
      new mapkit.Coordinate(flight.to.latitude, flight.to.longitude)];
    });

    const airports = flights.map((flight) => {
      return [flight.from, flight.to];
    });

    const airportsFlat = [].concat.apply([], airports);

    const airportsFiltered = airportsFlat.filter((airport, index) => {
      return index === airportsFlat.findIndex(obj => airport.iata === obj.iata);
    });

    const annotations = airportsFiltered.map((airport) => {
      return new mapkit.MarkerAnnotation(new mapkit.Coordinate(airport.latitude, airport.longitude),
        { color: this.darkmode.matches ? '#594C96' : '#f4a56d', title: airport.iata, glyphText: '✈️' });
    });
    this.map.showItems(annotations);
    const style = new mapkit.Style({
      lineWidth: 2,
      lineJoin: 'round',
      lineCap: 'round',
      lineDash: [10, 5],
    });

    flightCoords.forEach(coords => {
      const polyline = new mapkit.PolylineOverlay(coords, { style });
      this.map.addOverlay(polyline);
    });

    this.recenterMap();
  }

  ngAfterViewInit(): void {
    const mapkit = (this.windowService.nativeWindow as any).mapkit;

    mapkit.init({
      authorizationCallback: async (done) => {
        const res = await axios.get(`${this.urlBase}/maptoken`);
        done(res.data.token);
      },
      language: 'en'
    });

    this.addMapItems();

    this.map = new mapkit.Map('map', {
      colorScheme: this.darkmode.matches ? 'dark' : 'light',
    });

    // tslint:disable-next-line: deprecation
    this.darkmode.addListener(() => {
      this.map.colorScheme = this.darkmode.matches ? 'dark' : 'light';
    });
    // Always hide the compass.
    this.map.showsCompass = mapkit.FeatureVisibility.Hidden;

    // Hide the map type and zoom controls.
    this.map.showsMapTypeControl = false;
    this.map.showsZoomControl = false;

    this.recenterMap();
  }

}
