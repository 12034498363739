import { Component, AfterViewInit } from '@angular/core';
import { WindowRefService } from './window.service';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  providers: [WindowRefService],
})

export class AppComponent implements AfterViewInit {
  readonly currentYear = moment().format('YYYY');

  ngAfterViewInit(): void {

  }
}
