<div id="personalContainer" class="parallax_group content">
    <div class="container parallax__layer--back" id="nameContainer">
        <div id="profileName">
            <h1>Yannik Ehlert</h1><br/>
            <h2>{{ job.currentJob.short }}</h2>
        </div>
        <span class="imageWrapper">
            <img id="profileImage" src="../../assets/yannik.webp" />
        </span>
    </div>
</div>
<div class="parallax_group container" id="cv">
    <div id="mapTextOverlay" class="parallax__layer parallax__layer--fore content">
        <h1>About me</h1>
        My name is Yannik Ehlert. I am {{ age }} years old and currently in the position of a {{ job.currentJob.long }} at {{ job.currentEmployer }}.<br> During my studies for a Bachelor of Business Informatics, which I completed in 2019, I continued
        to specialize in app development for iOS and Android after starting private, more complex projects in 2014. Furthermore, I gained experience in the development of backend systems and cloud functions in different languages, most notably Kotlin,
        Java, TypeScript and JavaScript.<br><br> During my studies I had residences in three German states, Lower Saxony, Baden-Württemberg and North Rhine-Westphalia, so I am very used to travelling. As part of my training, I was able to work for CLAAS
        in India for six months too - an experience I wouldn't want to miss anymore. Today, I am close to completing my Master studies in Münster.<br /><br /> If you like to get in touch, feel free to contact me via LinkedIn or XING. However, please refrain
        from sending me job offers.<br /><br />
        <i>Yannik</i>
    </div>
    <div class="parallax__layer parallax__layer--base">
        <div id="map"></div>
        <div id="mapSubtitle">
            Flight history, mostly since 2016. Data from <a target="_blank" href="https://my.flightradar24.com/yanniks">myFlightradar24</a>.
        </div>
    </div>
</div>